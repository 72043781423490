<template>
  <v-dialog v-model="state" persistent width="460px">
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form ref="form" @submit.prevent="handleSubmit(addNewOpeningStock)">
        <v-card>
          <v-card-title>
            <div class=" font-weight-bold blue-grey--text font">
              <div>Add Opening Stock To Lubes</div>
              <span class=" ft font-weight-medium font-size-sm mt-n3"
                >Set opening stock for newly added lubes</span
              >
            </div>
            <v-spacer></v-spacer>
            <v-progress-linear
              v-show="progress"
              class="mx-6"
              indeterminate
              color="cyan"
            ></v-progress-linear>

            <div class="d-flex flex-column flex-grow-1 mt-4">
              <ValidationProvider tag="div" class="d-flex flex-row flex-grow-1">
                <v-autocomplete
                  solo
                  dense
                  :items="
                    allBranches.filter(
                      branch => !existingBranches.includes(branch.id)
                    )
                  "
                  item-value="id"
                  item-text="name"
                  color="primary"
                  v-model="query"
                  :disabled="
                    allBranches.filter(
                      branch => !existingBranches.includes(branch.id)
                    ).length === 0
                  "
                  clearable
                  return-object
                  background-color="grey lighten-4"
                  append-icon="search"
                  class=" ft  font-weight-medium font-size-sm"
                  flat
                  placeholder="Search for branches by using their name ..."
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title class="ft font-weight-medium">
                        Search through all
                        <strong>branches</strong>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{ item }">
                    <span class="ft font-weight-medium font-size-sm">
                      {{ item.name }}
                    </span>
                  </template>
                </v-autocomplete>
              </ValidationProvider>
              <template v-if="branches.length > 0">
                <div
                  v-for="(product, index) in branches"
                  :key="index"
                  class="d-flex flex-column flex-grow-1 mt-n5 px-1 pt-4 grey lighten-4"
                >
                  <div class="d-flex flex-column flex-grow-1">
                    <ValidationProvider
                      tag="div"
                      class="d-flex flex-row flex-grow-1"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        dense
                        label="Branch Name"
                        outlined
                        class="pa-1 ft font-weight-medium font-size-sm"
                        v-model="product.name"
                        readonly
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                  <div class="d-flex flex-row flex-grow-1 mt-n5">
                    <ValidationProvider
                      tag="div"
                      class="d-flex flex-column"
                      rules="required|numeric"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        dense
                        outlined
                        v-model="product.quantity"
                        class="pa-1 ft font-weight-medium font-size-sm"
                        label="Quantity"
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      tag="div"
                      class="d-flex flex-column"
                      rules="required|numeric"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        dense
                        outlined
                        v-model="product.minimumThreshold"
                        class="pa-1 ft font-weight-medium font-size-sm"
                        label="Min. Threshold"
                        :error-messages="errors[0]"
                      />
                    </ValidationProvider>
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          text
                          color="red"
                          @click="branches.splice(index, 1)"
                          small
                          icon
                          fab
                          class="mt-1"
                        >
                          <i class="material-icons-outlined">remove</i>
                        </v-btn>
                      </template>
                      <span class=" ft font-weight-medium font-size-sm"
                        >Remove
                      </span>
                    </v-tooltip>
                  </div>
                </div>
              </template>
              <template v-else>
                <div
                  class="d-flex flex-column flex-grow-1 py-5"
                  style="align-items: center;"
                >
                  <template
                    v-if="
                      allBranches.filter(
                        branch => !existingBranches.includes(branch.id)
                      ).length !== 0
                    "
                  >
                    <img
                      src="@/assets/oil-barrel.png"
                      alt="Search"
                      class="mb-3"
                      style="width: 60px"
                    />
                    <span
                      class="ft text-center font-weight-medium font-size-sm"
                    >
                      Click on a <kbd>search</kbd> results to add qty and min.
                      threshold
                    </span>
                    <span
                      class="red--text ft font-weight-medium font-size-sm font-italic"
                      >Opening stock can only be added once to a branch</span
                    >
                  </template>
                  <template v-else>
                    <img
                      src="@/assets/delivery-box.png"
                      alt="Search"
                      class="mb-3"
                      style="width: 80px"
                    />
                    <span
                      class="ft red--text text-center font-weight-medium font-size-sm"
                    >
                      Oops! sorry, you cannot add opening stock to lubes at this
                      time
                    </span>
                  </template>
                </div>
              </template>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="close"
              text
              class=" ft font-weight-medium font-size-sm"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="
                loading ||
                  allBranches.filter(
                    branch => !existingBranches.includes(branch.id)
                  ).length === 0
              "
              class="font font-weight-medium font-size-sm"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
/* eslint-disable */
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";
extend("required", {
  ...required,
  message: "Input field is required"
});
const even = {
  getMessage(field, args) {
    return "Field must contain even numbers eg. 2,4,6,8";
  },
  validate(value, args) {
    return value % 2 === 0;
  }
};
extend("even", {
  ...even,
  message: "Values should be 2,4,6,8 ..."
});
extend("numeric", {
  ...numeric,
  message: "Input field must be number"
});
export default {
  name: "AddOpeningStockDialog",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    state: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Boolean,
      default: false
    },
    details: {
      type: Object,
      default: null
    },
    allBranches: {
      type: Array,
      default: () => []
    },
    existingBranches: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    query(value) {
      if (value) {
        const { name, id } = value;
        this.branches = [
          ...this.branches.filter(branch => branch?.branchId !== value?.id),
          { branchId: id, name, quantity: null, minimumThreshold: null }
        ];
      }
      this.query = "";
      this.search = "";
    },
    resetFormState(value) {
      if (value) {
        this.branches = [];
        this.query = "";
        this.search = "";
      }
      setTimeout(
        () => this.$store.dispatch("resetFormState", false, { root: true }),
        2000
      );
    }
  },
  computed: {
    ...mapGetters({
      resetFormState: "getResetFormState"
    })
  },
  data: () => ({
    branches: [],
    search: null,
    query: null
  }),
  methods: {
    addNewOpeningStock() {
      if (this.branches.length > 0) {
        let payload = this.branches.map(({ name, ...rest }) => {
          return {
            branchId: rest.branchId,
            minimumThreshold: Number(rest.minimumThreshold),
            quantity: Number(rest.quantity)
          };
        });
        if (this.details) {
          this.$store.dispatch("products/addOpeningStock", {
            productId: this.details?.id,
            branches: payload
          });
        }
      } else {
        this.$store.dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: "Kindly add at least one branch",
            status: "red"
          },
          { root: true }
        );
      }
    },
    close() {
      this.$emit("actions", "details");
    }
  }
};
</script>
