var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"460px"},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addNewOpeningStock)}}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:" font-weight-bold blue-grey--text font"},[_c('div',[_vm._v("Add Opening Stock To Lubes")]),_c('span',{staticClass:" ft font-weight-medium font-size-sm mt-n3"},[_vm._v("Set opening stock for newly added lubes")])]),_c('v-spacer'),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.progress),expression:"progress"}],staticClass:"mx-6",attrs:{"indeterminate":"","color":"cyan"}}),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mt-4"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div"}},[_c('v-autocomplete',{staticClass:" ft  font-weight-medium font-size-sm",attrs:{"solo":"","dense":"","items":_vm.allBranches.filter(
                    function (branch) { return !_vm.existingBranches.includes(branch.id); }
                  ),"item-value":"id","item-text":"name","color":"primary","disabled":_vm.allBranches.filter(
                    function (branch) { return !_vm.existingBranches.includes(branch.id); }
                  ).length === 0,"clearable":"","return-object":"","background-color":"grey lighten-4","append-icon":"search","flat":"","placeholder":"Search for branches by using their name ..."},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',{staticClass:"ft font-weight-medium"},[_vm._v(" Search through all "),_c('strong',[_vm._v("branches")])])],1)]},proxy:true},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),(_vm.branches.length > 0)?_vm._l((_vm.branches),function(product,index){return _c('div',{key:index,staticClass:"d-flex flex-column flex-grow-1 mt-n5 px-1 pt-4 grey lighten-4"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-1 ft font-weight-medium font-size-sm",attrs:{"dense":"","label":"Branch Name","outlined":"","readonly":"","error-messages":errors[0]},model:{value:(product.name),callback:function ($$v) {_vm.$set(product, "name", $$v)},expression:"product.name"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 mt-n5"},[_c('ValidationProvider',{staticClass:"d-flex flex-column",attrs:{"tag":"div","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-1 ft font-weight-medium font-size-sm",attrs:{"dense":"","outlined":"","label":"Quantity","error-messages":errors[0]},model:{value:(product.quantity),callback:function ($$v) {_vm.$set(product, "quantity", $$v)},expression:"product.quantity"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-column",attrs:{"tag":"div","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-1 ft font-weight-medium font-size-sm",attrs:{"dense":"","outlined":"","label":"Min. Threshold","error-messages":errors[0]},model:{value:(product.minimumThreshold),callback:function ($$v) {_vm.$set(product, "minimumThreshold", $$v)},expression:"product.minimumThreshold"}})]}}],null,true)}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-1",attrs:{"text":"","color":"red","small":"","icon":"","fab":""},on:{"click":function($event){return _vm.branches.splice(index, 1)}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("remove")])])]}}],null,true)},[_c('span',{staticClass:" ft font-weight-medium font-size-sm"},[_vm._v("Remove ")])])],1)])}):[_c('div',{staticClass:"d-flex flex-column flex-grow-1 py-5",staticStyle:{"align-items":"center"}},[(
                    _vm.allBranches.filter(
                      function (branch) { return !_vm.existingBranches.includes(branch.id); }
                    ).length !== 0
                  )?[_c('img',{staticClass:"mb-3",staticStyle:{"width":"60px"},attrs:{"src":require("@/assets/oil-barrel.png"),"alt":"Search"}}),_c('span',{staticClass:"ft text-center font-weight-medium font-size-sm"},[_vm._v(" Click on a "),_c('kbd',[_vm._v("search")]),_vm._v(" results to add qty and min. threshold ")]),_c('span',{staticClass:"red--text ft font-weight-medium font-size-sm font-italic"},[_vm._v("Opening stock can only be added once to a branch")])]:[_c('img',{staticClass:"mb-3",staticStyle:{"width":"80px"},attrs:{"src":require("@/assets/delivery-box.png"),"alt":"Search"}}),_c('span',{staticClass:"ft red--text text-center font-weight-medium font-size-sm"},[_vm._v(" Oops! sorry, you cannot add opening stock to lubes at this time ")])]],2)]],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:" ft font-weight-medium font-size-sm",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Close ")]),_c('v-btn',{staticClass:"font font-weight-medium font-size-sm",attrs:{"color":"primary","type":"submit","loading":_vm.loading,"disabled":_vm.loading ||
                _vm.allBranches.filter(
                  function (branch) { return !_vm.existingBranches.includes(branch.id); }
                ).length === 0}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }